import { Divider, Flex, Statistic } from 'antd';
import { memo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import lang from 'src/lang/lang';
import { Routes } from 'src/routes';
import { IBaseProps } from 'src/types';

interface IProps extends IBaseProps {}

function CheckDeviceProvider(props: IProps) {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('accesstoken');
    if (!token) {
      navigate(Routes.LOGIN.path);
    }
  }, []);

  const detectMobileOS = () => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 2;
    }

    if (/android/i.test(userAgent)) {
      return 1;
    }

    return false;
  };

  if (detectMobileOS()) {
    return (
      <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ textAlign: 'center', margin: '0 20px' }}>
          <p style={{ textAlign: 'center' }}>{lang.t('provider.use_on_pc')}</p>
          <Divider plain>
            <span>{lang.t('provider.or')}</span>
          </Divider>
          <Link to={Routes.DOWNLOAD.path}>
            <Flex justify="center">
              {lang.t('provider.download_app')}{' '}
              <Statistic.Countdown
                valueStyle={{ fontSize: 14, color: 'var(--colorNormal)', marginLeft: 4 }}
                value={Date.now() + 5 * 1000}
                onFinish={() => {
                  navigate(Routes.DOWNLOAD.path);
                }}
                format="s"
              />
              s
            </Flex>
          </Link>
        </div>
      </div>
    );
  }

  return props.children;
}

export default memo(CheckDeviceProvider);
