/* eslint-disable no-case-declarations */
import { DownloadOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { memo, useState } from 'react';
import lang from 'src/lang/lang';

function Chat() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Popover
      placement="bottomRight"
      title={<b style={{ fontSize: '18px' }}>{lang.t('layout.download')}</b>}
      trigger="click"
      content={<img src="/image/qr-download.jpg" width="200px" alt="download" />}
      onOpenChange={(open) => {
        setOpen(open);
      }}
      arrow={false}
      // style={{ height, overflow: 'scroll' }}
      overlayClassName="custom-popover"
      open={open}
      destroyTooltipOnHide
    >
      <div className="icon-wrapper">
        <DownloadOutlined style={{ color: 'var(--colorNormal)', fontSize: 22 }} />
      </div>
    </Popover>
  );
}

export default memo(Chat);
