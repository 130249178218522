/* eslint-disable no-case-declarations */
import { FileDoneOutlined, TikTokOutlined } from '@ant-design/icons';
import { Avatar, Badge, Empty, List, Skeleton, Spin, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { memo, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { ECollection } from 'src/services';
import NotificationService from 'src/services/notification.service';
import { useUser } from 'src/stores';
import { ENotificationStatus, EService, INotification, IUser } from 'src/types';
import { FUNCS } from 'src/utils';

interface IProps {
  onClose: () => void;
  newItem?: INotification;
}

interface IState {
  data: INotification[];
  loading: boolean;
  page: number;
  total: number;
}

const initState: IState = {
  data: [],
  loading: false,
  page: 1,
  total: 0,
};

const sv = new NotificationService();

function NotificationPopover(props: IProps) {
  const [state, setState] = useState<IState>(initState);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.newItem) {
      setState((prev) => ({ ...prev, data: [props.newItem as INotification, ...prev.data] }));
    }
  }, [props.newItem]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (state.loading) return;
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const res = await sv.list({
        limit: 10,
        page: 1,
        fields: [
          'id',
          'subject',
          'message',
          'status',
          'collection',
          'item',
          'sender.first_name',
          'sender.last_name',
          'sender.avatar',
          'timestamp',
        ],
        filter: {
          recipient: { _eq: user?.id },
          // collection: { _neq: ECollection.conversation },
        },
        sort: ['-timestamp'],
      });

      const total = await sv.getTotal({
        filter: {
          recipient: { _eq: user?.id },
          // collection: { _neq: ECollection.conversation },
        },
      });

      setState((prev) => ({ ...prev, data: res, total }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const onLoadMore = async () => {
    try {
      const res = await sv.list({
        limit: 10,
        page: state.page + 1,
        fields: [
          'id',
          'subject',
          'message',
          'status',
          'collection',
          'item',
          'sender.first_name',
          'sender.last_name',
          'sender.avatar',
          'timestamp',
        ],
        filter: {
          recipient: { _eq: user?.id },
          // collection: { _neq: ECollection.conversation },
        },
        sort: ['-timestamp'],
      });

      setState((prev) => ({ ...prev, data: [...prev.data, ...res], page: prev.page + 1 }));
    } catch (error) {
      console.log(error);
    }
  };

  const onGoDetail = (item: INotification) => {
    let path = '';
    switch (item.collection) {
      case ECollection.checkout:
        path = `/${FUNCS.getServicePath(EService.checkout)}/${item.item}`;
        break;

      default:
        break;
    }

    if (item.status !== ENotificationStatus.seen) {
      sv.update(item.id, { status: ENotificationStatus.seen });
    }

    setState((prev) => ({
      ...prev,
      data: prev.data.map((i) => {
        if (i.id === item.id) {
          return { ...i, status: ENotificationStatus.seen };
        }
        return i;
      }),
    }));

    navigate(path);

    props.onClose();
  };

  return (
    <div className="custom-popover-content" style={{ marginTop: -6 }}>
      <Spin spinning={state.loading}>
        <div
          id="notification"
          style={{
            height: 400,
            overflow: 'auto',
          }}
        >
          {state.data.length === 0 && <Empty />}
          {state.data.length > 0 && (
            <InfiniteScroll
              dataLength={state.data.length}
              next={onLoadMore}
              hasMore={state.data.length < state.total}
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              scrollableTarget="notification"
            >
              <List
                dataSource={state.data}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onGoDetail(item)}
                    className="noti-list-item"
                  >
                    <List.Item.Meta
                      avatar={
                        <Badge
                          count={
                            <Tag className="icon-wrapper-notification" color="purple">
                              <FileDoneOutlined />
                            </Tag>
                          }
                          offset={[0, 38]}
                        >
                          {item.collection === ECollection.checkout && !item.sender ? (
                            <Avatar size={48} icon={<TikTokOutlined />} style={{ backgroundColor: 'black' }} />
                          ) : (
                            <Avatar size={48} src={FUNCS.getFullMedialUrl((item.sender as IUser)?.avatar)} />
                          )}
                        </Badge>
                      }
                      title={
                        <div>
                          <strong>{item.subject}</strong>{' '}
                          <Typography.Text style={{ fontWeight: 400, fontSize: 10 }} type="secondary">
                            {dayjs(item.timestamp).fromNow()}
                          </Typography.Text>
                        </div>
                      }
                      description={`${item.message}`}
                    />
                    {item.status === ENotificationStatus.inbox && <div className="unseen-dot" />}
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default memo(NotificationPopover);
