import { ICheckout } from './checkout.type';
import { IBaseEntity, IBaseQuery, IFee, IRelationBody } from './common.type';
import { IDebt, IDebtBody } from './debt.type';
import { IPackProduct, IPackProductBody } from './pack_product.type';

export interface ITurnback extends IBaseEntity {
  code: string;
  note: string;
  status: ETurnbackStatus;
  status_history: ITurnbackHistory[];
  total: number;
  status_payment: ETurnbackStatusPayment;
  checkout_id: ICheckout;
  fees: IFee[];
  debts: IDebt[];
  // turnback_products: ITurnbackProduct[];
  pack_products: IPackProduct[];
}

export interface ITurnbackQuery extends IBaseQuery {
  code?: string;
  dateRange?: string[];
  turnback_products?: string;
}

export interface ITurnbackBody {
  code?: string;
  note?: string;
  status?: ETurnbackStatus;
  status_history?: ITurnbackHistory[];
  total?: number;
  status_payment?: ETurnbackStatusPayment;
  checkout_id?: string | { id?: string };
  fees?: IFee[];
  debts?: IRelationBody<IDebtBody>;
  pack_products?: IRelationBody<IPackProductBody>;
}

export interface ITurnbackHistory {
  status: ETurnbackStatus;
  date: string;
}

export enum ETurnbackStatus {
  CANCEL,
  CREATED,
  APPROVED,
  SHIPPING,
  RESTORED,
}

export enum ETurnbackStatusPayment {
  NOT_PAID = 1,
  PARTIAL_PAID = 2,
  PAID = 3,
}
